/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState, useCallback } from 'react'
import type { ReactNode } from 'react'
import classNames from 'classnames'
import { getRefObject } from './helper'

interface ItemProps {
  data: AccordionData
  isOpen: boolean
  onClick: () => void
  className?: string
}

type AccordionData = {
  title: string
  content: ReactNode
  icon?: ReactNode
}

type AccordionProps = {
  items: Array<AccordionData>
}

const Item = ({ data: { title, content }, isOpen, onClick, className }: ItemProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (isOpen) {
      const contentEl = getRefObject(contentRef)
      setHeight(contentEl.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [isOpen])

  return (
    <li onClick={onClick} className={classNames(className, 'flex flex-col  rounded-md bg-[#FFFFFF0D] p-5')}>
      <div className={`relative flex cursor-pointer items-center font-medium transition-colors duration-200`}>
        {' '}
        <div className={` flex w-6 shrink-0 items-center justify-center`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="currentColor"
            className={`h-3 w-3 ${
              isOpen ? 'rotate-180 text-[#764C99]' : 'text-neutral-500'
            } transition-all duration-200`}
            viewBox="0 0 16 16"
          >
            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
          </svg>
        </div>
        <div
          className={`grow pointer-events-none gap-3 flex text-base  lg:text-xl font-black  ${
            isOpen ? 'text-white' : 'text-[#B3B4B7]'
          }`}
        >
          <div>{title}</div>
        </div>
      </div>
      <div className={`overflow-hidden transition-all duration-300 ease-out `} style={{ height }}>
        <div
          ref={contentRef}
          className="p-6 font-light text-[#B3B4B7] text-xs leading-relaxed tracking-wider lg:text-base"
        >
          {content}
        </div>
      </div>
    </li>
  )
}

export const Accordion = ({ items }: AccordionProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleOpen = useCallback((index: number) => {
    setCurrentIndex((currentValue) => (currentValue !== index ? index : -1))
  }, [])

  return (
    <ul className="space-y-3">
      {items.map((item, index) => (
        <Item key={index} data={item} isOpen={index === currentIndex} onClick={() => handleOpen(index)} />
      ))}
    </ul>
  )
}
