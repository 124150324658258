/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */

import useTheme from 'hooks/useTheme'

import { PageMeta } from 'components/Layout/Page'
// import { useTranslation } from '@pancakeswap/localization'

import Footer from './components/Footer'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Image from 'next/image'
import { Button } from '@pancakeswap/uikit'
import { Accordion } from './components/Accordion'
import Link from 'next/link'
import { useWeb3React } from '@pancakeswap/wagmi'

interface CardProps {
  title: string
  description: string
  onClick: () => void
  type?: 'mobile' | 'dekstop'
}

const Card: React.FC<CardProps> = ({ title, description, onClick, type = 'dekstop' }) => {
  return (
    <motion.div
      className={`bg-[#FFFFFF0D] p-6 cursor-pointer   ${type === 'mobile' ? 'h-[468px]' : 'h-full lg:h-80'}`}
      onClick={onClick}
    >
      <motion.h2 className="text-[64px] font-bold mb-2 text-[#B3B4B7]">{title}</motion.h2>
      <p className="text-white text-5xl font-bold">{description}</p>
    </motion.div>
  )
}

const slideCardVariants = {
  initial: {
    opacity: 0,
    translateY: '0%',
  },
  open: {
    opacity: 1,
    translateY: '-100%',
    transition: {
      duration: 0.5,
      type: 'spring',
      stiffness: 200,
    },
  },
  exit: {
    opacity: 0,
    translateY: '100%',
    transition: {
      duration: 0.5,
    },
  },
}

const slideCardVariantsMobile = {
  initial: {
    opacity: 0,
    translateX: '-100%',
  },
  open: {
    opacity: 1,
    translateX: '0%',
    transition: {
      duration: 0.5,
      type: 'spring',
      stiffness: 200,
    },
  },
  exit: {
    opacity: 0,
    translateX: '100%',
    transition: {
      duration: 0.5,
    },
  },
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const accordionItems = [
  {
    title: 'What is City Swap?',
    content: (
      <div>
        City Swap is a decentralized cryptocurrency exchange platform designed to provide users with a seamless trading
        experience. Built on the principles of transparency, security, and accessibility, City Swap empowers users to
        trade a wide range of cryptocurrencies with ease. Whether you're an experienced trader or new to the world of
        cryptocurrency, City Swap offers intuitive features and robust security measures to ensure a smooth and reliable
        trading experience.
      </div>
    ),
  },
  {
    title: 'How is the Bridge Secured?',
    content: (
      <div>
        The bridge on City Swap is secured through a combination of cutting-edge technology and rigorous security
        protocols. Our team employs industry best practices to safeguard user assets and ensure the integrity of
        transactions across the bridge. This includes multi-signature authentication, encryption mechanisms, and regular
        security audits conducted by independent third parties. Additionally, our platform adheres to strict compliance
        standards to mitigate the risk of vulnerabilities and unauthorized access.
      </div>
    ),
  },
  {
    title: 'How to Earn Reward?',
    content: (
      <div>
        Earning rewards on City Swap is simple and rewarding. Users can participate in various activities such as
        staking and liquidity provision to earn rewards in the form of tokens. By staking your assets or providing
        liquidity to the platform, you contribute to the stability and growth of City Swap while earning passive income
        in return. Our reward distribution mechanism is designed to incentivize active participation and foster a
        thriving community of users.
      </div>
    ),
  },

  {
    title: 'How to Swap Token?',
    content: (
      <div>
        Swapping tokens on City Swap is quick and easy. To initiate a token swap, simply navigate to the exchange
        interface on our platform and select the tokens you wish to trade. Enter the desired amount for each token,
        review the transaction details, and confirm the swap. Our decentralized exchange algorithm will automatically
        match your order with the best available rates, ensuring optimal execution of your trade. Once the swap is
        completed, you'll receive the swapped tokens directly into your wallet, ready for further use or investment.
      </div>
    ),
  },
]

const Home: React.FC<React.PropsWithChildren> = () => {
  // const { theme } = useTheme()
  const { account } = useWeb3React()
  // const { chainId } = useActiveChainId()

  const [showInfo, setShowInfo] = useState(true)
  const [showInfo2, setShowInfo2] = useState(false)
  const [showInfo3, setShowInfo3] = useState(false)

  const handleCardClick = () => {
    setShowInfo(!showInfo)
    setShowInfo2(false)
    setShowInfo3(false)
  }

  const handleClickCardTwo = () => {
    setShowInfo2(!showInfo2)
    setShowInfo(false)
    setShowInfo3(false)
  }

  const handleClickCardThree = () => {
    setShowInfo3(!showInfo3)
    setShowInfo2(false)
    setShowInfo(false)
  }

  const { setTheme } = useTheme()
  setTheme('dark')

  // const { t } = useTranslation()

  const targetRef = useRef<HTMLDivElement | null>(null)
  const slideSolution = useRef<HTMLDivElement | null>(null)

  // const { scrollYProgress } = useScroll({
  //   target: targetRef,
  // })

  // const scaleX = useSpring(scrollYProgress, {
  //   stiffness: 100,
  //   damping: 10,
  // })

  // const sunY = useTransform(scaleX, [0, 0.4], ['0%', '-30%'])

  // const textY = useTransform(scaleX, [0, 0.45], ['0%', '-70%'])
  // const opacity = useTransform(scaleX, [0.1, 0.45], [1, 0])

  // const scaleCity = useTransform(scaleX, [0, 0.45], [1, 1.1], {
  //   ease: cubicBezier(0.35, 0.45, 0.65, 0.95),
  // })

  // const xCity1 = useTransform(scaleX, [0, 0.45], ['0px', '-50px'])
  // const scaleCity1 = useTransform(scaleX, [0, 0.45], [1.2, 1.5], {
  //   clamp: true,
  //   ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
  // })

  // const xCity2 = useTransform(scaleX, [0, 0.45], ['0px', '50px'])
  // const scaleCity2 = useTransform(scaleX, [0, 0.45], [1.2, 1.5])

  return (
    <>
      <PageMeta />

      <AnimatePresence>
        <div className=" -mx-[24px]  lg:!-mx-[120px] inset-x-0 -mt-10 overflow-x-hidden">
          <motion.section layout ref={targetRef} className={`relative h-100vh    pb-12 `}>
            <motion.div
              layout
              className="flex h-screen sticky snap-center  top-0  items-center   flex-col text-center  bg-gradient-to-b from-[#31338b] to-[#6c4696]   overflow-hidden"
            >
              <div className=" absolute bg-cover bg-bottom  h-full w-full bg-[url('/images/star.png')]" />
              <div className="mt-32 lg:w-2/5 z-30 p-5 lg:p-0 lg:mt-48">
                <motion.h1
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      scale: {
                        ease: 'easeIn',
                        type: 'spring',
                        duration: 1.2,
                      },
                    },
                  }}
                  viewport={{
                    once: true,
                  }}
                  layout
                  className="text-white font-black font-brand text-4xl lg:text-[64px] mb-10"
                >
                  CITY SWAP
                </motion.h1>
                <motion.h1
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      scale: {
                        ease: 'easeIn',
                        type: 'spring',
                        duration: 1.2,
                      },
                    },
                  }}
                  viewport={{
                    once: true,
                  }}
                  className="text-white text-base text-[16px] mb-10"
                >
                  "City Swap Token redefines the way you trade and interact with digital assets, creating a seamless and
                  secure ecosystem for all your swapping needs."
                </motion.h1>
                <motion.div
                  initial={{
                    opacity: 0,
                    scale: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      scale: {
                        ease: 'easeIn',
                        type: 'spring',
                        duration: 1.2,
                      },
                    },
                  }}
                  viewport={{
                    once: true,
                  }}
                >
                  <div className="flex items-center justify-center gap-4">
                    {!account && <ConnectWalletButton mr="8px" />}
                    <Link href="/swap">
                      <button className="border border-white text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] hover:border-[#764C99] rounded-lg">
                        Trade Now
                      </button>
                    </Link>
                  </div>
                </motion.div>
              </div>

              <motion.div
                initial={{
                  y: 0,
                }}
                whileInView={{
                  y: -350,
                  transition: {
                    y: {
                      ease: 'easeIn',
                      type: 'spring',
                      duration: 1.2,
                    },
                  },
                }}
                viewport={{
                  once: true,
                }}
                className="absolute -z-20 bottom-20 lg:-bottom-[80%]"
              >
                <img className="scale-150 lg:scale-100" src="/images/sun.svg" alt="" />
              </motion.div>
              <motion.div
                initial={{
                  scale: 1,
                }}
                whileInView={{
                  scale: 1.4,
                  transition: {
                    scale: {
                      ease: 'easeIn',
                      type: 'spring',
                      duration: 1.3,
                    },
                  },
                }}
                viewport={{
                  once: true,
                }}
                className="absolute bottom-1 lg:-bottom-[30%] -z-10"
              >
                <img src="/images/city2.svg" alt="" />
              </motion.div>
              <motion.div
                initial={{
                  scale: 1,
                }}
                whileInView={{
                  scale: 1.3,
                  transition: {
                    scale: {
                      ease: 'easeIn',
                      type: 'spring',
                      duration: 1.2,
                      mass: 2,
                      stiffness: 200,
                    },
                  },
                }}
                viewport={{
                  once: true,
                }}
                className="absolute bottom-0 lg:bottom-0 -left-5  "
              >
                <img src="/images/cityleft.svg" alt="" />
              </motion.div>
              <motion.div
                initial={{
                  scale: 1,
                }}
                whileInView={{
                  scale: 1.5,
                  transition: {
                    scale: {
                      ease: 'easeIn',
                      type: 'spring',
                      duration: 1.3,
                      mass: 2,
                      stiffness: 100,
                    },
                  },
                }}
                viewport={{
                  once: true,
                }}
                className="absolute bottom-20 lg:bottom-0 -right-20 "
              >
                <img src="/images/cityleft.svg" alt="" />
              </motion.div>
            </motion.div>
            <div className="bg-[#202241] absolute bottom-5 lg:hidden  w-full h-40 z-10 left-auto right-auto" />
          </motion.section>
        </div>
      </AnimatePresence>

      <AnimatePresence>
        <div className="relative pb-12">
          <motion.div ref={slideSolution}>
            <Image alt="city" src="/images/solution.png" width={1439} height={331} />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,
            }}
            className="bg-[#222548]/[0.7] h-full absolute top-0 w-full"
          >
            <div className="justify-center h-full text-center flex items-center">
              <div className="lg:w-3/6">
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  className="text-center text-base lg:text-4xl font-black mb-4"
                >
                  Your DeFi Solution
                </motion.div>
                <motion.p
                  className=" text-xs  lg:text-lg text-center lg:font-semibold text-[#b3b4b7]"
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                >
                  "Next-gen cross-chain DeFi solutions paving the way for a diversified and interconnected financial
                  ecosystem."
                </motion.p>
              </div>
            </div>
          </motion.div>
        </div>
      </AnimatePresence>

      <div className="justify-center text-center pb-12 mt-5 flex items-center">
        <div className="lg:w-8/12">
          <motion.div
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-base lg:text-[32px] font-black mb-4"
          >
            What Kind Feature We Offer For You
          </motion.div>
          <motion.div
            initial={{ y: 60, opacity: 0 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-xs lg:text-[16px] lg:font-bold text-[#b3b4b7] leading-relaxed"
          >
            "City Swap introduces a user-friendly and efficient platform that enables instant asset exchange, ensuring
            hassle-free transactions across a wide range of digital assets."
          </motion.div>
        </div>
      </div>

      <section className="pt-48 hidden lg:block">
        <div className="  lg:grid grid-cols-1 lg:grid-cols-3">
          <div className="relative">
            <Card title="01" description="Trade" onClick={handleCardClick} />
            <AnimatePresence>
              {showInfo && (
                <motion.div
                  className="cardGradient relative cursor-pointer shadow-lg h-[468px] p-6  bottom-0 left-0 w-full"
                  initial="initial"
                  whileInView="open"
                  animate={showInfo ? 'open' : 'initial'}
                  exit="exit"
                  variants={slideCardVariants}
                  // onClick={handleCardClick}
                >
                  <Image
                    className="absolute right-0 top-0"
                    src="https://city-swap.netlify.app/images/landing/trade.png"
                    width={120}
                    height={120}
                    alt="images"
                  />
                  <div className="mb-12">
                    <motion.h2 className="text-4xl font-bold mb-2 text-[#B3B4B7]">01</motion.h2>
                    <motion.h2 className="text-4xl font-bold mb-2 text-white">Trade</motion.h2>
                  </div>
                  <motion.p variants={variants} className="text-sm font-black leading-loose text-[#B3B4B7]">
                    "With City Swap's advanced trading features, users can confidently and swiftly execute trades,
                    capitalizing on market opportunities and optimizing their asset portfolio."
                  </motion.p>

                  <div className="flex absolute items-center gap-4 bottom-6">
                    <Link href="/swap">
                      <Button>Trade Now</Button>
                    </Link>
                    <button className="border border-[#764C99] text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] rounded-lg">
                      Learn More
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative  ">
            <Card title="02" description="Bridge" onClick={handleClickCardTwo} />
            <AnimatePresence>
              {showInfo2 && (
                <motion.div
                  className="cardGradient cursor-pointer relative shadow-lg   h-[468px] p-6  bottom-0 left-0 w-full"
                  initial="initial"
                  whileInView="open"
                  animate={showInfo2 ? 'open' : 'initial'}
                  exit="exit"
                  variants={slideCardVariants}
                  // onClick={handleCardClick}
                >
                  <Image
                    className="absolute right-0 top-0"
                    src="https://city-swap.netlify.app/images/landing/trade.png"
                    width={120}
                    height={120}
                    alt="images"
                  />
                  <div className="mb-12">
                    <motion.h2 className="text-4xl font-bold mb-2 text-[#B3B4B7]">02</motion.h2>
                    <motion.h2 className="text-4xl font-bold mb-2 text-white">Bridge</motion.h2>
                  </div>
                  <motion.p variants={variants} className="text-sm font-black leading-loose text-[#B3B4B7]">
                    "With City Swap's advanced trading features, users can confidently and swiftly execute trades,
                    capitalizing on market opportunities and optimizing their asset portfolio."
                  </motion.p>

                  <div className="flex absolute items-center gap-4 bottom-6">
                    <Link href="/swap">
                      <Button>Trade Now</Button>
                    </Link>
                    <button className="border border-[#764C99] text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] rounded-lg">
                      Learn More
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative  ">
            <Card title="03" description="Earn" onClick={handleClickCardThree} />
            <AnimatePresence>
              {showInfo3 && (
                <motion.div
                  className="cardGradient relative cursor-pointer shadow-lg h-[468px] p-6  bottom-0 left-0 w-full"
                  initial="initial"
                  whileInView="open"
                  animate={showInfo3 ? 'open' : 'initial'}
                  exit="exit"
                  variants={slideCardVariants}
                >
                  <Image
                    className="absolute right-0 top-0"
                    src="https://city-swap.netlify.app/images/landing/trade.png"
                    width={120}
                    height={120}
                    alt="images"
                  />
                  <div className="mb-12">
                    <motion.h2 className="text-4xl font-bold mb-2 text-[#B3B4B7]">03</motion.h2>
                    <motion.h2 className="text-4xl font-bold mb-2 text-white">Earn</motion.h2>
                  </div>
                  <motion.p variants={variants} className="text-sm font-black leading-loose text-[#B3B4B7]">
                    "With City Swap's advanced trading features, users can confidently and swiftly execute trades,
                    capitalizing on market opportunities and optimizing their asset portfolio."
                  </motion.p>

                  <div className="flex absolute items-center gap-4 bottom-6">
                    <Link href="/swap">
                      <Button>Trade Now</Button>
                    </Link>
                    <button className="border border-[#764C99] text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] rounded-lg">
                      Learn More
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </section>

      <section className="pt-10 pb-20 lg:hidden block">
        <div className="  lg:grid grid-cols-1 lg:grid-cols-3">
          <div className="relative h-fit">
            <Card type="mobile" title="01" description="Trade" onClick={handleCardClick} />
            <AnimatePresence>
              {showInfo && (
                <motion.div
                  className="cardGradient absolute shadow-lg h-[468px] p-6 top-0 w-full"
                  initial="initial"
                  whileInView="open"
                  animate={showInfo ? 'open' : 'initial'}
                  exit="exit"
                  variants={slideCardVariantsMobile}
                  // onClick={handleCardClick}
                >
                  <Image
                    className="absolute right-0 top-0"
                    src="https://city-swap.netlify.app/images/landing/trade.png"
                    width={120}
                    height={120}
                    alt="images"
                  />
                  <div className="mb-12">
                    <motion.h2 className="text-4xl font-bold mb-2 text-[#B3B4B7]">01</motion.h2>
                    <motion.h2 className="text-4xl font-bold mb-2 text-white">Trade</motion.h2>
                  </div>
                  <motion.p variants={variants} className="text-sm font-black leading-loose text-[#B3B4B7]">
                    "With City Swap's advanced trading features, users can confidently and swiftly execute trades,
                    capitalizing on market opportunities and optimizing their asset portfolio."
                  </motion.p>

                  <div className="flex absolute items-center gap-4 bottom-6">
                    <Link href="/swap">
                      <Button>Trade Now</Button>
                    </Link>
                    <button className="border border-[#764C99] text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] rounded-lg">
                      Learn More
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative  ">
            <Card type="mobile" title="02" description="Bridge" onClick={handleClickCardTwo} />
            <AnimatePresence>
              {showInfo2 && (
                <motion.div
                  className="cardGradient absolute shadow-lg h-[468px] p-6 top-0 w-full"
                  initial="initial"
                  whileInView="open"
                  animate={showInfo2 ? 'open' : 'initial'}
                  exit="exit"
                  variants={slideCardVariantsMobile}
                  // onClick={handleCardClick}
                >
                  <Image
                    className="absolute right-0 top-0"
                    src="https://city-swap.netlify.app/images/landing/trade.png"
                    width={120}
                    height={120}
                    alt="images"
                  />
                  <div className="mb-12">
                    <motion.h2 className="text-4xl font-bold mb-2 text-[#B3B4B7]">02</motion.h2>
                    <motion.h2 className="text-4xl font-bold mb-2 text-white">Bridge</motion.h2>
                  </div>
                  <motion.p variants={variants} className="text-sm font-black leading-loose text-[#B3B4B7]">
                    "With City Swap's advanced trading features, users can confidently and swiftly execute trades,
                    capitalizing on market opportunities and optimizing their asset portfolio."
                  </motion.p>

                  <div className="flex absolute items-center gap-4 bottom-6">
                    <Link href="/swap">
                      <Button>Trade Now</Button>
                    </Link>
                    <button className="border border-[#764C99] text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] rounded-lg">
                      Learn More
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="relative  ">
            <Card type="mobile" title="03" description="Earn" onClick={handleClickCardThree} />
            <AnimatePresence>
              {showInfo3 && (
                <motion.div
                  className="cardGradient absolute shadow-lg h-[468px] p-6 top-0 w-full"
                  initial="initial"
                  whileInView="open"
                  animate={showInfo3 ? 'open' : 'initial'}
                  exit="exit"
                  variants={slideCardVariantsMobile}
                >
                  <Image
                    className="absolute right-0 top-0"
                    src="https://city-swap.netlify.app/images/landing/trade.png"
                    width={120}
                    height={120}
                    alt="images"
                  />
                  <div className="mb-12">
                    <motion.h2 className="text-4xl font-bold mb-2 text-[#B3B4B7]">03</motion.h2>
                    <motion.h2 className="text-4xl font-bold mb-2 text-white">Earn</motion.h2>
                  </div>
                  <motion.p variants={variants} className="text-sm font-black leading-loose text-[#B3B4B7]">
                    "With City Swap's advanced trading features, users can confidently and swiftly execute trades,
                    capitalizing on market opportunities and optimizing their asset portfolio."
                  </motion.p>

                  <div className="flex absolute items-center gap-4 bottom-6">
                    <Link href="/swap">
                      <Button>Trade Now</Button>
                    </Link>
                    <button className="border border-[#764C99] text-white p-3 transition-colors ease-in-out duration-300 font-bold hover:bg-[#764C99] rounded-lg">
                      Learn More
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </section>

      <div className="text-white lg:-mt-[400px]  mb-10 lg:mb-40">
        <div className="flex flex-col relative items-center justify-center text-center">
          <div className="lg:w-8/12">
            <motion.div
              initial={{ y: 60, opacity: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-white text-base lg:text-4xl mb-3 lg:mb-10 font-black"
            >
              Our Roadmap
            </motion.div>
            <motion.div
              initial={{ y: 60, opacity: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-[#B3B4B7] text-xs lg:text-base lg:font-semibold"
            >
              "The City Swap roadmap outlines a strategic journey towards enhanced functionalities, expanded
              partnerships, and global adoption, setting the stage for a groundbreaking evolution in digital asset
              swapping."
            </motion.div>
          </div>
          <div className="hidden lg:block">
            <svg width={1007} height={214} viewBox="0 0 1007 214" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M218.457 108.9c0 13.698-2.826 27.262-8.315 39.917-5.489 12.656-13.535 24.154-23.678 33.84-10.142 9.686-22.184 17.37-35.436 22.611-13.252 5.242-27.456 7.94-41.8 7.94-14.344 0-28.547-2.698-41.8-7.94-13.252-5.241-25.293-12.925-35.436-22.611s-18.188-21.184-23.677-33.84C2.825 136.162 0 122.598 0 108.9h21.846c0 10.959 2.26 21.81 6.651 31.934 4.392 10.124 10.828 19.323 18.943 27.072 8.114 7.749 17.747 13.895 28.348 18.089a90.985 90.985 0 0033.44 6.352 90.983 90.983 0 0033.44-6.352c10.602-4.194 20.235-10.34 28.349-18.089 8.114-7.749 14.551-16.948 18.942-27.072a80.184 80.184 0 006.652-31.934h21.846zM613.384 104.308c0 13.698-2.842 27.262-8.364 39.917-5.522 12.655-13.616 24.154-23.82 33.84-10.204 9.686-22.317 17.369-35.649 22.611-13.332 5.242-27.621 7.94-42.051 7.94-14.43 0-28.719-2.698-42.051-7.94-13.332-5.242-25.445-12.925-35.649-22.611s-18.298-21.185-23.82-33.84c-5.522-12.655-8.364-26.219-8.364-39.917h21.977c0 10.958 2.273 21.81 6.691 31.934 4.418 10.124 10.893 19.323 19.056 27.072 8.163 7.748 17.854 13.895 28.519 18.089a92.019 92.019 0 0033.641 6.352 92.019 92.019 0 0033.641-6.352c10.665-4.194 20.356-10.341 28.519-18.089 8.163-7.749 14.638-16.948 19.056-27.072 4.418-10.124 6.692-20.976 6.692-31.934h21.976zM1007 108.9c0 13.698-2.83 27.262-8.315 39.917-5.489 12.656-13.535 24.154-23.678 33.84-10.142 9.686-22.184 17.37-35.436 22.611-13.252 5.242-27.456 7.94-41.8 7.94-14.344 0-28.547-2.698-41.8-7.94-13.252-5.241-25.293-12.925-35.436-22.611s-18.188-21.184-23.678-33.84c-5.489-12.655-8.314-26.219-8.314-39.917h21.846c0 10.959 2.26 21.81 6.651 31.934 4.392 10.124 10.828 19.323 18.942 27.072 8.115 7.749 17.748 13.895 28.349 18.089a90.987 90.987 0 0033.44 6.352 90.983 90.983 0 0033.44-6.352c10.602-4.194 20.235-10.34 28.349-18.089 8.114-7.749 14.551-16.948 18.942-27.072a80.184 80.184 0 006.652-31.934H1007z"
                fill="#634394"
              />
              <path
                d="M196.946 109.322c-.018-13.704 2.792-27.28 8.268-39.95 5.477-12.67 13.513-24.188 23.651-33.896 10.138-9.707 22.178-17.415 35.433-22.682 13.256-5.267 27.467-7.99 41.822-8.015 14.355-.024 28.573 2.65 41.842 7.872 13.27 5.222 25.33 12.888 35.494 22.561 10.163 9.673 18.23 21.163 23.74 33.815 5.511 12.652 8.356 26.217 8.374 39.921l-21.862.037a80.204 80.204 0 00-6.699-31.936c-4.408-10.122-10.862-19.314-18.993-27.052-8.131-7.739-17.779-13.872-28.395-18.049a91.042 91.042 0 00-33.473-6.298 91.09 91.09 0 00-33.458 6.412c-10.604 4.214-20.236 10.38-28.346 18.146s-14.54 16.98-18.921 27.117c-4.381 10.136-6.629 20.996-6.614 31.959l-21.863.038zM591.442 109.509c-.019-13.705 2.791-27.28 8.267-39.95 5.477-12.67 13.514-24.188 23.651-33.896 10.138-9.707 22.178-17.415 35.434-22.682 13.255-5.267 27.466-7.99 41.821-8.015 14.355-.025 28.573 2.65 41.842 7.872 13.27 5.222 25.33 12.888 35.494 22.56 10.163 9.674 18.23 21.164 23.741 33.816 5.51 12.651 8.355 26.217 8.373 39.921l-21.862.037c-.014-10.963-2.291-21.815-6.699-31.937-4.408-10.12-10.862-19.313-18.993-27.052-8.13-7.738-17.779-13.87-28.394-18.048a91.053 91.053 0 00-33.474-6.298 91.085 91.085 0 00-33.457 6.412c-10.605 4.214-20.237 10.38-28.347 18.146-8.11 7.766-14.539 16.98-18.921 27.117-4.381 10.136-6.629 20.996-6.614 31.959l-21.862.038z"
                fill="#2E2F7A"
              />
              <ellipse cx={109.578} cy={101.96} rx={51.1918} ry={52.7576} fill="#634394" />
              <ellipse cx={109.849} cy={101.885} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M102.016 113.384a18.68 18.68 0 01-5.153-.704c-1.642-.491-2.965-1.12-3.968-1.888l1.76-3.904c.96.682 2.091 1.248 3.392 1.696 1.323.426 2.657.64 4.001.64 1.024 0 1.845-.096 2.464-.288.64-.214 1.109-.502 1.408-.864.298-.363.448-.779.448-1.248 0-.598-.235-1.067-.704-1.408-.47-.363-1.088-.651-1.856-.864a27.944 27.944 0 00-2.56-.64 36.859 36.859 0 01-2.785-.768 11.914 11.914 0 01-2.527-1.152 5.818 5.818 0 01-1.889-1.856c-.469-.768-.703-1.75-.703-2.944 0-1.28.34-2.443 1.024-3.488.704-1.067 1.749-1.91 3.136-2.528 1.407-.64 3.168-.96 5.28-.96 1.408 0 2.794.17 4.16.512 1.365.32 2.57.81 3.616 1.472l-1.6 3.936a13.107 13.107 0 00-3.136-1.312 11.157 11.157 0 00-3.072-.448c-1.003 0-1.824.117-2.464.352-.64.234-1.1.544-1.376.928-.278.362-.416.789-.416 1.28 0 .576.234 1.045.704 1.408.469.34 1.088.618 1.856.832.768.213 1.61.426 2.528.64.938.213 1.866.458 2.784.736.938.277 1.792.65 2.56 1.12a5.52 5.52 0 011.856 1.856c.49.768.736 1.738.736 2.912 0 1.258-.352 2.41-1.056 3.456-.704 1.045-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm14.461-.384V92.52l2.24 2.24h-6.72V90.6h9.664V113h-5.184z"
                fill="#634394"
              />
              <ellipse cx={304.418} cy={101.96} rx={51.1918} ry={52.7576} fill="#2E2F7A" />
              <ellipse cx={304.689} cy={101.885} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M297.016 113.384c-1.792 0-3.509-.235-5.152-.704-1.642-.491-2.965-1.12-3.968-1.888l1.76-3.904c.96.682 2.091 1.248 3.392 1.696a12.98 12.98 0 004 .64c1.024 0 1.846-.096 2.464-.288.64-.214 1.11-.502 1.408-.864.299-.363.448-.779.448-1.248 0-.598-.234-1.067-.704-1.408-.469-.363-1.088-.651-1.856-.864a27.862 27.862 0 00-2.56-.64 37.017 37.017 0 01-2.784-.768 11.92 11.92 0 01-2.528-1.152 5.821 5.821 0 01-1.888-1.856c-.469-.768-.704-1.75-.704-2.944 0-1.28.342-2.443 1.024-3.488.704-1.067 1.75-1.91 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.17 4.16.512 1.366.32 2.571.81 3.616 1.472l-1.6 3.936a13.077 13.077 0 00-3.136-1.312 11.146 11.146 0 00-3.072-.448c-1.002 0-1.824.117-2.464.352-.64.235-1.098.544-1.376.928-.277.362-.416.79-.416 1.28 0 .576.235 1.045.704 1.408.47.341 1.088.619 1.856.832.768.213 1.611.427 2.528.64.939.213 1.867.458 2.784.736.939.277 1.792.65 2.56 1.12a5.51 5.51 0 011.856 1.856c.491.768.736 1.738.736 2.912 0 1.258-.352 2.41-1.056 3.456-.704 1.045-1.76 1.888-3.168 2.528-1.386.64-3.146.96-5.28.96zm11.127-.384v-3.36l8.64-8.16c.682-.619 1.184-1.174 1.504-1.664.32-.49.533-.939.64-1.344.128-.405.192-.779.192-1.12 0-.896-.31-1.579-.928-2.048-.598-.49-1.483-.736-2.656-.736-.939 0-1.814.181-2.624.544-.79.363-1.462.928-2.016 1.696l-3.776-2.432c.853-1.28 2.048-2.294 3.584-3.04 1.536-.747 3.306-1.12 5.312-1.12 1.664 0 3.114.277 4.352.832 1.258.533 2.229 1.29 2.912 2.272.704.981 1.056 2.155 1.056 3.52 0 .725-.096 1.45-.288 2.176-.171.704-.534 1.45-1.088 2.24-.534.789-1.323 1.674-2.368 2.656l-7.168 6.752-.992-1.888h12.64V113h-16.928z"
                fill="#2E2F7A"
              />
              <ellipse cx={501.882} cy={101.96} rx={51.1918} ry={52.7576} fill="#634394" />
              <ellipse cx={502.153} cy={101.885} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M494.016 113.384c-1.792 0-3.509-.235-5.152-.704-1.642-.491-2.965-1.12-3.968-1.888l1.76-3.904c.96.682 2.091 1.248 3.392 1.696a12.98 12.98 0 004 .64c1.024 0 1.846-.096 2.464-.288.64-.214 1.11-.502 1.408-.864.299-.363.448-.779.448-1.248 0-.598-.234-1.067-.704-1.408-.469-.363-1.088-.651-1.856-.864a27.862 27.862 0 00-2.56-.64 37.017 37.017 0 01-2.784-.768 11.92 11.92 0 01-2.528-1.152 5.821 5.821 0 01-1.888-1.856c-.469-.768-.704-1.75-.704-2.944 0-1.28.342-2.443 1.024-3.488.704-1.067 1.75-1.91 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.17 4.16.512 1.366.32 2.571.81 3.616 1.472l-1.6 3.936a13.077 13.077 0 00-3.136-1.312 11.146 11.146 0 00-3.072-.448c-1.002 0-1.824.117-2.464.352-.64.235-1.098.544-1.376.928-.277.362-.416.79-.416 1.28 0 .576.235 1.045.704 1.408.47.341 1.088.619 1.856.832.768.213 1.611.427 2.528.64.939.213 1.867.458 2.784.736.939.277 1.792.65 2.56 1.12a5.51 5.51 0 011.856 1.856c.491.768.736 1.738.736 2.912 0 1.258-.352 2.41-1.056 3.456-.704 1.045-1.76 1.888-3.168 2.528-1.386.64-3.146.96-5.28.96zm18.489 0a18.13 18.13 0 01-4.64-.608c-1.536-.427-2.837-1.024-3.904-1.792l2.016-3.968c.853.618 1.845 1.109 2.976 1.472 1.131.362 2.272.544 3.424.544 1.301 0 2.325-.256 3.072-.768.747-.512 1.12-1.216 1.12-2.112 0-.854-.331-1.526-.992-2.016-.661-.491-1.728-.736-3.2-.736h-2.368v-3.424l6.24-7.072.576 1.856h-11.744V90.6h15.68v3.36l-6.208 7.072-2.624-1.504h1.504c2.752 0 4.832.618 6.24 1.856 1.408 1.237 2.112 2.826 2.112 4.768a6.829 6.829 0 01-.992 3.552c-.661 1.088-1.675 1.973-3.04 2.656-1.365.682-3.115 1.024-5.248 1.024z"
                fill="#634394"
              />
              <ellipse cx={701.314} cy={107.864} rx={51.1918} ry={52.7576} fill="#2E2F7A" />
              <ellipse cx={701.585} cy={107.789} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M694.016 119.384a18.64 18.64 0 01-5.152-.704c-1.643-.49-2.965-1.12-3.968-1.888l1.76-3.904c.96.683 2.091 1.248 3.392 1.696a12.95 12.95 0 004 .64c1.024 0 1.845-.096 2.464-.288.64-.213 1.109-.501 1.408-.864.299-.362.448-.778.448-1.248 0-.597-.235-1.066-.704-1.408-.469-.362-1.088-.65-1.856-.864a28.19 28.19 0 00-2.56-.64 36.09 36.09 0 01-2.784-.768 11.822 11.822 0 01-2.528-1.152 5.811 5.811 0 01-1.888-1.856c-.469-.768-.704-1.749-.704-2.944 0-1.28.341-2.442 1.024-3.488.704-1.066 1.749-1.91 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.17 4.16.512 1.365.32 2.571.81 3.616 1.472l-1.6 3.936a13.135 13.135 0 00-3.136-1.312 11.173 11.173 0 00-3.072-.448c-1.003 0-1.824.118-2.464.352-.64.235-1.099.544-1.376.928a2.05 2.05 0 00-.416 1.28c0 .576.235 1.046.704 1.408.469.342 1.088.619 1.856.832.768.214 1.611.427 2.528.64a41.56 41.56 0 012.784.736c.939.278 1.792.651 2.56 1.12a5.52 5.52 0 011.856 1.856c.491.768.736 1.739.736 2.912a6.054 6.054 0 01-1.056 3.456c-.704 1.046-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm11.375-5.088v-3.488l10.56-14.208h5.44l-10.336 14.208-2.528-.736h17.632v4.224h-20.768zM717.455 119v-4.704l.16-4.224v-4.192h4.896V119h-5.056z"
                fill="#2E2F7A"
              />
              <ellipse cx={899.434} cy={107.864} rx={51.1918} ry={52.7576} fill="#634394" />
              <ellipse cx={899.706} cy={107.789} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M892.016 119.384a18.64 18.64 0 01-5.152-.704c-1.643-.49-2.965-1.12-3.968-1.888l1.76-3.904c.96.683 2.091 1.248 3.392 1.696a12.95 12.95 0 004 .64c1.024 0 1.845-.096 2.464-.288.64-.213 1.109-.501 1.408-.864.299-.362.448-.778.448-1.248 0-.597-.235-1.066-.704-1.408-.469-.362-1.088-.65-1.856-.864a28.19 28.19 0 00-2.56-.64 36.09 36.09 0 01-2.784-.768 11.822 11.822 0 01-2.528-1.152 5.811 5.811 0 01-1.888-1.856c-.469-.768-.704-1.749-.704-2.944 0-1.28.341-2.442 1.024-3.488.704-1.066 1.749-1.91 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.17 4.16.512 1.365.32 2.571.81 3.616 1.472l-1.6 3.936a13.135 13.135 0 00-3.136-1.312 11.173 11.173 0 00-3.072-.448c-1.003 0-1.824.118-2.464.352-.64.235-1.099.544-1.376.928a2.05 2.05 0 00-.416 1.28c0 .576.235 1.046.704 1.408.469.342 1.088.619 1.856.832.768.214 1.611.427 2.528.64a41.56 41.56 0 012.784.736c.939.278 1.792.651 2.56 1.12a5.52 5.52 0 011.856 1.856c.491.768.736 1.739.736 2.912a6.054 6.054 0 01-1.056 3.456c-.704 1.046-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm18.934 0c-1.557 0-3.104-.202-4.64-.608-1.514-.426-2.816-1.024-3.904-1.792l2.048-3.968c.854.619 1.835 1.11 2.944 1.472 1.131.363 2.272.544 3.424.544 1.302 0 2.326-.256 3.072-.768.747-.512 1.12-1.226 1.12-2.144 0-.576-.149-1.088-.448-1.536-.298-.448-.832-.789-1.6-1.024-.746-.234-1.802-.352-3.168-.352h-5.728l1.152-12.608h13.664v4.16h-11.808l2.688-2.368-.8 8.992-2.688-2.368h4.736c2.219 0 4 .31 5.344.928 1.366.598 2.358 1.419 2.976 2.464.619 1.046.928 2.23.928 3.552 0 1.323-.33 2.55-.992 3.68-.661 1.11-1.685 2.016-3.072 2.72-1.365.683-3.114 1.024-5.248 1.024z"
                fill="#634394"
              />
              <circle cx={10.8244} cy={107.26} r={10.8244} fill="#634394" />
              <circle cx={996.176} cy={109.884} r={10.8244} fill="#634394" />
            </svg>
          </div>
          <div className="w-[calc(100%_-_14rem)] md:w-[calc(100%_-_40rem)] mt-10 lg:hidden">
            <svg viewBox="0 0 215 1007" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M105.44 218.457c-13.698 0-27.262-2.826-39.917-8.315-12.655-5.489-24.154-13.535-33.84-23.678-9.686-10.142-17.37-22.184-22.611-35.436-5.242-13.252-7.94-27.456-7.94-41.8 0-14.344 2.698-28.547 7.94-41.8 5.242-13.252 12.925-25.293 22.61-35.436C41.37 21.85 52.869 13.804 65.523 8.315 78.179 2.825 91.743 0 105.44 0v21.846c-10.958 0-21.81 2.26-31.934 6.651C63.382 32.89 54.183 39.325 46.434 47.44c-7.748 8.114-13.895 17.747-18.089 28.348a90.973 90.973 0 00-6.352 33.44 90.971 90.971 0 006.352 33.44c4.194 10.602 10.34 20.235 18.09 28.349 7.748 8.114 16.947 14.551 27.071 18.942a80.186 80.186 0 0031.934 6.652v21.846zM110.032 613.384c-13.698 0-27.262-2.842-39.917-8.364-12.655-5.522-24.154-13.616-33.84-23.82-9.686-10.204-17.37-22.317-22.611-35.649-5.242-13.332-7.94-27.621-7.94-42.051 0-14.43 2.698-28.719 7.94-42.051 5.242-13.332 12.925-25.445 22.61-35.649 9.687-10.204 21.185-18.298 33.84-23.82 12.656-5.522 26.22-8.364 39.918-8.364v21.977c-10.959 0-21.81 2.273-31.934 6.691-10.124 4.418-19.323 10.893-27.072 19.056-7.749 8.163-13.895 17.854-18.089 28.519a92.007 92.007 0 00-6.352 33.641 92.007 92.007 0 006.352 33.641c4.194 10.665 10.34 20.356 18.09 28.519 7.748 8.163 16.947 14.638 27.071 19.056 10.124 4.418 20.975 6.692 31.934 6.692v21.976zM105.44 1007c-13.698 0-27.262-2.83-39.917-8.314-12.655-5.49-24.154-13.535-33.84-23.678-9.686-10.143-17.37-22.184-22.611-35.436-5.242-13.253-7.94-27.456-7.94-41.8 0-14.344 2.698-28.548 7.94-41.8 5.242-13.252 12.925-25.294 22.61-35.436 9.687-10.143 21.186-18.189 33.84-23.678 12.656-5.489 26.22-8.315 39.918-8.315v21.846a80.186 80.186 0 00-31.934 6.652c-10.124 4.391-19.323 10.828-27.072 18.942-7.748 8.114-13.895 17.747-18.089 28.349a90.975 90.975 0 00-6.352 33.44 90.975 90.975 0 006.352 33.44c4.194 10.601 10.34 20.234 18.09 28.349 7.748 8.114 16.947 14.55 27.071 18.942 10.124 4.391 20.976 6.651 31.934 6.651V1007z"
                fill="#634394"
              />
              <path
                d="M105.206 196.946c13.704.006 27.274 2.838 39.935 8.336 12.661 5.499 24.165 13.555 33.855 23.709 9.691 10.155 17.377 22.208 22.622 35.472 5.244 13.265 7.943 27.48 7.943 41.835s-2.699 28.569-7.943 41.829c-5.245 13.261-12.931 25.308-22.622 35.455-9.69 10.147-21.194 18.194-33.855 23.683-12.661 5.489-26.231 8.311-39.935 8.306v-21.863a80.194 80.194 0 0031.948-6.644c10.129-4.391 19.332-10.829 27.084-18.947 7.752-8.117 13.902-17.755 18.097-28.364a91.037 91.037 0 006.355-33.463 91.079 91.079 0 00-6.355-33.468c-4.195-10.611-10.345-20.254-18.097-28.377-7.752-8.124-16.955-14.569-27.084-18.967-10.129-4.399-20.985-6.665-31.948-6.669v-21.863zM105.018 591.44c13.704.006 27.274 2.838 39.935 8.337 12.661 5.498 24.165 13.554 33.856 23.708 9.69 10.155 17.377 22.208 22.621 35.472 5.245 13.265 7.944 27.48 7.944 41.835 0 14.356-2.699 28.569-7.944 41.829-5.244 13.261-12.931 25.308-22.621 35.455-9.691 10.147-21.195 18.194-33.856 23.683-12.661 5.489-26.231 8.311-39.935 8.306v-21.863a80.194 80.194 0 0031.948-6.644c10.129-4.391 19.332-10.829 27.085-18.947 7.752-8.117 13.901-17.755 18.097-28.363a91.063 91.063 0 006.355-33.464 91.1 91.1 0 00-6.355-33.468c-4.196-10.611-10.345-20.254-18.097-28.377-7.753-8.124-16.956-14.569-27.085-18.967-10.129-4.399-20.985-6.665-31.948-6.669V591.44z"
                fill="#2E2F7A"
              />
              <ellipse cx={112.381} cy={109.578} rx={51.1918} ry={52.7576} fill="#634394" />
              <ellipse cx={112.652} cy={109.503} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M104.819 121.002c-1.792 0-3.51-.235-5.152-.704-1.643-.491-2.966-1.12-3.968-1.888l1.76-3.904c.96.683 2.09 1.248 3.392 1.696 1.322.427 2.656.64 4 .64 1.024 0 1.845-.096 2.464-.288.64-.213 1.109-.501 1.408-.864.298-.363.448-.779.448-1.248 0-.597-.235-1.067-.704-1.408-.47-.363-1.088-.651-1.856-.864a27.944 27.944 0 00-2.56-.64 35.844 35.844 0 01-2.784-.768 11.9 11.9 0 01-2.528-1.152 5.808 5.808 0 01-1.888-1.856c-.47-.768-.704-1.749-.704-2.944 0-1.28.341-2.443 1.024-3.488.704-1.067 1.75-1.91 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.794.17 4.16.512 1.365.32 2.57.81 3.616 1.472l-1.6 3.936a13.11 13.11 0 00-3.136-1.312 11.146 11.146 0 00-3.072-.448c-1.003 0-1.824.117-2.464.352-.64.235-1.099.544-1.376.928a2.043 2.043 0 00-.416 1.28c0 .576.234 1.045.704 1.408.469.341 1.088.619 1.856.832.768.213 1.61.427 2.528.64.938.213 1.866.459 2.784.736.938.277 1.792.651 2.56 1.12a5.52 5.52 0 011.856 1.856c.49.768.736 1.739.736 2.912a6.054 6.054 0 01-1.056 3.456c-.704 1.045-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm14.461-.384v-20.48l2.24 2.24h-6.72v-4.16h9.664v22.4h-5.184z"
                fill="#634394"
              />
              <ellipse cx={112.381} cy={304.418} rx={51.1918} ry={52.7576} fill="#2E2F7A" />
              <ellipse cx={112.652} cy={304.343} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M104.981 315.842a18.68 18.68 0 01-5.152-.704c-1.643-.491-2.965-1.12-3.968-1.888l1.76-3.904c.96.683 2.09 1.248 3.392 1.696a12.95 12.95 0 004 .64c1.024 0 1.845-.096 2.464-.288.64-.213 1.109-.501 1.408-.864.299-.363.448-.779.448-1.248 0-.597-.235-1.067-.704-1.408-.469-.363-1.088-.651-1.856-.864a27.862 27.862 0 00-2.56-.64 36.09 36.09 0 01-2.784-.768 11.922 11.922 0 01-2.528-1.152 5.808 5.808 0 01-1.888-1.856c-.47-.768-.704-1.749-.704-2.944 0-1.28.341-2.443 1.024-3.488.704-1.067 1.75-1.909 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.171 4.16.512 1.365.32 2.571.811 3.616 1.472l-1.6 3.936a13.094 13.094 0 00-3.136-1.312 11.135 11.135 0 00-3.072-.448c-1.003 0-1.824.117-2.464.352-.64.235-1.099.544-1.376.928a2.049 2.049 0 00-.416 1.28c0 .576.235 1.045.704 1.408.469.341 1.088.619 1.856.832.768.213 1.611.427 2.528.64.939.213 1.867.459 2.784.736.939.277 1.792.651 2.56 1.12a5.51 5.51 0 011.856 1.856c.491.768.736 1.739.736 2.912a6.054 6.054 0 01-1.056 3.456c-.704 1.045-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm11.126-.384v-3.36l8.64-8.16c.683-.619 1.184-1.173 1.504-1.664.32-.491.533-.939.64-1.344.128-.405.192-.779.192-1.12 0-.896-.309-1.579-.928-2.048-.597-.491-1.483-.736-2.656-.736-.939 0-1.813.181-2.624.544-.789.363-1.461.928-2.016 1.696l-3.776-2.432c.853-1.28 2.048-2.293 3.584-3.04 1.536-.747 3.307-1.12 5.312-1.12 1.664 0 3.115.277 4.352.832 1.259.533 2.229 1.291 2.912 2.272.704.981 1.056 2.155 1.056 3.52 0 .725-.096 1.451-.288 2.176-.171.704-.533 1.451-1.088 2.24-.533.789-1.323 1.675-2.368 2.656l-7.168 6.752-.992-1.888h12.64v4.224h-16.928z"
                fill="#2E2F7A"
              />
              <ellipse cx={112.381} cy={501.882} rx={51.1918} ry={52.7576} fill="#634394" />
              <ellipse cx={112.652} cy={501.807} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M104.516 513.306c-1.792 0-3.509-.235-5.152-.704-1.643-.491-2.965-1.12-3.968-1.888l1.76-3.904c.96.683 2.09 1.248 3.392 1.696a12.95 12.95 0 004 .64c1.024 0 1.845-.096 2.464-.288.64-.213 1.109-.501 1.408-.864.299-.363.448-.779.448-1.248 0-.597-.235-1.067-.704-1.408-.469-.363-1.088-.651-1.856-.864a27.862 27.862 0 00-2.56-.64 36.09 36.09 0 01-2.784-.768 11.92 11.92 0 01-2.528-1.152 5.808 5.808 0 01-1.888-1.856c-.47-.768-.704-1.749-.704-2.944 0-1.28.341-2.443 1.024-3.488.704-1.067 1.75-1.909 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.171 4.16.512 1.365.32 2.571.811 3.616 1.472l-1.6 3.936a13.094 13.094 0 00-3.136-1.312 11.135 11.135 0 00-3.072-.448c-1.003 0-1.824.117-2.464.352-.64.235-1.099.544-1.376.928a2.049 2.049 0 00-.416 1.28c0 .576.235 1.045.704 1.408.469.341 1.088.619 1.856.832.768.213 1.611.427 2.528.64.939.213 1.867.459 2.784.736.939.277 1.792.651 2.56 1.12a5.51 5.51 0 011.856 1.856c.491.768.736 1.739.736 2.912a6.054 6.054 0 01-1.056 3.456c-.704 1.045-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm18.489 0c-1.558 0-3.104-.203-4.64-.608-1.536-.427-2.838-1.024-3.904-1.792l2.016-3.968c.853.619 1.845 1.109 2.976 1.472 1.13.363 2.272.544 3.424.544 1.301 0 2.325-.256 3.072-.768.746-.512 1.12-1.216 1.12-2.112 0-.853-.331-1.525-.992-2.016-.662-.491-1.728-.736-3.2-.736h-2.368v-3.424l6.24-7.072.576 1.856h-11.744v-4.16h15.68v3.36l-6.208 7.072-2.624-1.504h1.504c2.752 0 4.832.619 6.24 1.856 1.408 1.237 2.112 2.827 2.112 4.768a6.823 6.823 0 01-.992 3.552c-.662 1.088-1.675 1.973-3.04 2.656-1.366.683-3.115 1.024-5.248 1.024z"
                fill="#634394"
              />
              <ellipse cx={106.477} cy={701.314} rx={51.1918} ry={52.7576} fill="#2E2F7A" />
              <ellipse cx={106.748} cy={701.239} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M99.18 712.834c-1.792 0-3.51-.234-5.152-.704-1.643-.49-2.965-1.12-3.968-1.888l1.76-3.904c.96.683 2.09 1.248 3.392 1.696 1.323.427 2.656.64 4 .64 1.024 0 1.845-.096 2.464-.288.64-.213 1.109-.501 1.408-.864.299-.362.448-.778.448-1.248 0-.597-.235-1.066-.704-1.408-.469-.362-1.088-.65-1.856-.864a28.221 28.221 0 00-2.56-.64 35.992 35.992 0 01-2.784-.768 11.813 11.813 0 01-2.528-1.152 5.807 5.807 0 01-1.888-1.856c-.47-.768-.704-1.749-.704-2.944 0-1.28.341-2.442 1.024-3.488.704-1.066 1.75-1.909 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.171 4.16.512 1.365.32 2.571.811 3.616 1.472l-1.6 3.936a13.135 13.135 0 00-3.136-1.312 11.173 11.173 0 00-3.072-.448c-1.003 0-1.824.118-2.464.352-.64.235-1.099.544-1.376.928-.277.363-.416.79-.416 1.28 0 .576.235 1.046.704 1.408.47.342 1.088.619 1.856.832.768.214 1.61.427 2.528.64a41.56 41.56 0 012.784.736c.939.278 1.792.651 2.56 1.12a5.52 5.52 0 011.856 1.856c.491.768.736 1.739.736 2.912a6.054 6.054 0 01-1.056 3.456c-.704 1.046-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm11.375-5.088v-3.488l10.56-14.208h5.44l-10.336 14.208-2.528-.736h17.632v4.224h-20.768zm12.064 4.704v-4.704l.16-4.224v-4.192h4.896v13.12h-5.056z"
                fill="#2E2F7A"
              />
              <ellipse cx={106.477} cy={899.433} rx={51.1918} ry={52.7576} fill="#634394" />
              <ellipse cx={106.748} cy={899.359} rx={39.611} ry={39.5903} fill="#F4F4F4" />
              <path
                d="M99.059 910.954c-1.792 0-3.51-.235-5.152-.704-1.643-.491-2.965-1.12-3.968-1.888l1.76-3.904c.96.682 2.09 1.248 3.392 1.696 1.323.426 2.656.64 4 .64 1.024 0 1.845-.096 2.464-.288.64-.214 1.109-.502 1.408-.864.299-.363.448-.779.448-1.248 0-.598-.235-1.067-.704-1.408-.469-.363-1.088-.651-1.856-.864a27.895 27.895 0 00-2.56-.64 36.912 36.912 0 01-2.784-.768 11.912 11.912 0 01-2.528-1.152 5.817 5.817 0 01-1.888-1.856c-.47-.768-.704-1.75-.704-2.944 0-1.28.341-2.443 1.024-3.488.704-1.067 1.75-1.91 3.136-2.528 1.408-.64 3.168-.96 5.28-.96 1.408 0 2.795.17 4.16.512 1.365.32 2.571.81 3.616 1.472l-1.6 3.936a13.054 13.054 0 00-3.136-1.312 11.135 11.135 0 00-3.072-.448c-1.003 0-1.824.117-2.464.352-.64.234-1.099.544-1.376.928a2.044 2.044 0 00-.416 1.28c0 .576.235 1.045.704 1.408.47.341 1.088.618 1.856.832.768.213 1.61.426 2.528.64.939.213 1.867.458 2.784.736.939.277 1.792.65 2.56 1.12a5.51 5.51 0 011.856 1.856c.491.768.736 1.738.736 2.912 0 1.258-.352 2.41-1.056 3.456-.704 1.045-1.76 1.888-3.168 2.528-1.387.64-3.147.96-5.28.96zm18.934 0a18.13 18.13 0 01-4.64-.608c-1.514-.427-2.816-1.024-3.904-1.792l2.048-3.968c.854.618 1.835 1.109 2.944 1.472 1.131.362 2.272.544 3.424.544 1.302 0 2.326-.256 3.072-.768.747-.512 1.12-1.227 1.12-2.144 0-.576-.149-1.088-.448-1.536-.298-.448-.832-.79-1.6-1.024-.746-.235-1.802-.352-3.168-.352h-5.728l1.152-12.608h13.664v4.16h-11.808l2.688-2.368-.8 8.992-2.688-2.368h4.736c2.219 0 4 .309 5.344.928 1.366.597 2.358 1.418 2.976 2.464.619 1.045.928 2.229.928 3.552 0 1.322-.33 2.549-.992 3.68-.661 1.109-1.685 2.016-3.072 2.72-1.365.682-3.114 1.024-5.248 1.024z"
                fill="#634394"
              />
              <circle cx={107.08} cy={10.8244} r={10.8244} transform="rotate(90 107.08 10.824)" fill="#634394" />
              <circle cx={104.455} cy={996.175} r={10.8244} transform="rotate(90 104.455 996.175)" fill="#634394" />
            </svg>
          </div>
        </div>
      </div>

      <div className="pb-60 mb-56 lg:h-screen">
        <div className="flex flex-col lg:flex-row lg:order-1 lg:justify-between items-center gap-10">
          <div className="w-full order-2">
            <Accordion items={accordionItems} />
          </div>
          <div className="w-full text-right order-1 lg:order-2">
            <h1 className="font-black text-base md:text-3xl mb-4 text-center md:text-right">
              Frequently Asked Question
            </h1>
            <div className="lg:font-bold text-xs font-medium md:text-base text-[#B3B4B7] text-center md:text-right ">
              To be the leading provider of innovative and reliable technology solutions, empowering businesses to
              thrive in a connected world
            </div>
            <div className="flex justify-center lg:justify-end   lg:w-full  mt-10">
              <Image
                src="/images/logocity.png"
                className="w-[327px] h-[327px] lg:w-[544px] lg:h-[544px]"
                width={500}
                height={500}
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Home
